import { ComponentProps, useState } from "react";
import { AccessButton } from "@acdc2/ui/components/access-button";
import { Button } from "@acdc2/ui/components/button";
import { Trans, useTranslation } from "react-i18next";
import { DocumentExportCombobox } from "./DocumentExportCombobox";
import { DocumentExportArtifact } from "./DocumentExportArtifact";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@acdc2/ui/components/dialog";
import {
  DocumentExportDialogFragment,
  ExporterType,
  useCreateArtifactMutation,
} from "../client/generated";

type Props = ComponentProps<typeof Dialog> & {
  fragment: DocumentExportDialogFragment;
};

export function DocumentExportDialog({
  fragment,
  children,
  ...dialogProps
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [exportType, setExportType] = useState<ExporterType>();
  const [isLoading, setIsLoading] = useState(false);

  const [createArtifact] = useCreateArtifactMutation();

  const exportDocument = async () => {
    if (!exportType) return;

    setIsLoading(true);

    try {
      await createArtifact({
        variables: {
          exportType,
          documentId: fragment.id,
        },
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog {...dialogProps}>
      {children}
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t("DocumentExportDialog.title")}</DialogTitle>
          <DialogDescription>
            <Trans
              i18nKey="DocumentExportDialog.description"
              components={{ "word-break": <span className="break-all" /> }}
              values={{ name: fragment.name }}
            />
          </DialogDescription>
        </DialogHeader>

        <div className="flex flex-row gap-2">
          <DocumentExportCombobox value={exportType} onSelect={setExportType} />
          <AccessButton
            variant="outline"
            disabled={!exportType}
            onClick={exportDocument}
            loading={isLoading}
          >
            {t("DocumentExportDialog.submit")}
          </AccessButton>
        </div>

        <div className="flex flex-col gap-3">
          <DialogTitle asChild>
            <h3>{t("DocumentExportDialog.artifacts.title")}</h3>
          </DialogTitle>
          {fragment.artifacts.artifacts.length > 0 ? (
            <ul className="flex flex-col gap-4">
              {fragment.artifacts.artifacts.map((artifact) => (
                <li key={artifact.id}>
                  <DocumentExportArtifact fragment={artifact} />
                </li>
              ))}
            </ul>
          ) : (
            <span className="text-sm text-muted-foreground">
              {t("DocumentExportDialog.artifacts.placeholder")}
            </span>
          )}
        </div>

        <DialogFooter>
          <DialogClose asChild>
            <Button type="button">{t("DocumentExportDialog.done")}</Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
