import { Trans, useTranslation } from "react-i18next";
import { AccessButton } from "@acdc2/ui/components/access-button";
import { DocumentShareCombobox } from "./DocumentShareCombobox";
import { DocumentShareCollaborator } from "./DocumentShareCollaborator";
import { DocumentSharePermission } from "./DocumentSharePermission";
import { ComponentProps } from "react";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@acdc2/ui/components/dialog";
import {
  DocumentShareDialogFragment,
  useShareDocumentMutation,
} from "../client/generated";

type Props = ComponentProps<typeof Dialog> & {
  fragment: DocumentShareDialogFragment;
};

export function DocumentShareDialog({
  fragment,
  children,
  ...dialogProps
}: Props): JSX.Element {
  const { t } = useTranslation();

  const [shareDocument] = useShareDocumentMutation();

  const handleShare = async (userId: string) => {
    try {
      await shareDocument({
        variables: {
          documentId: fragment.id,
          userId,
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Dialog {...dialogProps}>
      {children}
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t("DocumentShareDialog.title")}</DialogTitle>
          <DialogDescription>
            <Trans
              i18nKey="DocumentShareDialog.description"
              components={{ "word-break": <span className="break-all" /> }}
              values={{ name: fragment.name }}
            />
          </DialogDescription>
        </DialogHeader>

        <DocumentShareCombobox
          fragment={fragment.viewerMembership.user.colleagues}
          onSelect={handleShare}
        />

        <div className="flex flex-col gap-3">
          <DialogTitle asChild>
            <h3>{t("DocumentShareDialog.collaborators.title")}</h3>
          </DialogTitle>
          <ul className="flex flex-col gap-4">
            {fragment.members.memberships.map((membership) => (
              <li key={membership.user.id}>
                <DocumentShareCollaborator
                  fragment={membership.user}
                  right={<DocumentSharePermission fragment={membership} />}
                />
              </li>
            ))}
          </ul>
        </div>

        <DialogFooter>
          <DialogClose asChild>
            <AccessButton>{t("DocumentShareDialog.done")}</AccessButton>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
