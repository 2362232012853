import { Trans, useTranslation } from "react-i18next";
import { Button } from "@acdc2/ui/components/button";
import { AccessButton } from "@acdc2/ui/components/access-button";
import { ComponentProps } from "react";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@acdc2/ui/components/dialog";
import {
  DocumentDeleteDialogFragment,
  useDeleteDocumentMutation,
} from "../client/generated";

type Props = ComponentProps<typeof Dialog> & {
  fragment: DocumentDeleteDialogFragment;
};

export function DocumentDeleteDialog({
  fragment,
  children,
  onOpenChange,
  ...dialogProps
}: Props): JSX.Element {
  const { t } = useTranslation();

  const [deleteDocument, { loading }] = useDeleteDocumentMutation({
    variables: { id: fragment.id },
    update(cache) {
      const id = cache.identify(fragment);
      cache.evict({ id });
      cache.gc();
    },
  });

  const onSubmit = async () => {
    try {
      await deleteDocument();
      onOpenChange?.(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Dialog onOpenChange={onOpenChange} {...dialogProps}>
      {children}
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t("DocumentDeleteDialog.title")}</DialogTitle>
          <DialogDescription>
            <Trans
              i18nKey="DocumentDeleteDialog.description"
              components={{ "word-break": <span className="break-all" /> }}
              values={{ name: fragment.name }}
            />
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <DialogClose asChild>
            <Button type="button" variant="secondary">
              {t("DocumentDeleteDialog.buttons.cancel")}
            </Button>
          </DialogClose>

          <AccessButton
            variant="destructive"
            onClick={onSubmit}
            loading={loading}
            disabled={loading}
            children={t("DocumentDeleteDialog.buttons.confirm")}
          />
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
