import { Trans, useTranslation } from "react-i18next";
import { AccessButton } from "@acdc2/ui/components/access-button";
import { DocumentTagsInput } from "./DocumentTagsInput";
import { DocumentTagsSuggestions } from "./DocumentTagsSuggestions";
import { ComponentProps } from "react";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@acdc2/ui/components/dialog";
import {
  DocumentTagsDropdownMenuItemFragment,
  useTagDocumentMutation,
  useUntagDocumentMutation,
} from "../client/generated";

type Props = ComponentProps<typeof Dialog> & {
  fragment: DocumentTagsDropdownMenuItemFragment;
};

export function DocumentTagsDialog({
  fragment,
  children,
  ...dialogProps
}: Props): JSX.Element {
  const { t } = useTranslation();

  const [tagDocument] = useTagDocumentMutation();
  const [untagDocument] = useUntagDocumentMutation();

  const onTagAdd = async (tag: string) => {
    try {
      await tagDocument({
        variables: {
          documentId: fragment.id,
          tag,
        },
        optimisticResponse: {
          addDocumentTag: {
            userErrors: [],
            document: {
              id: fragment.id,
              tags: [...fragment.tags, tag].sort(),
              possibleTags: fragment.possibleTags.filter((t) => t !== tag),
            },
          },
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const onTagRemove = async (tag: string) => {
    try {
      await untagDocument({
        variables: {
          documentId: fragment.id,
          tag,
        },
        optimisticResponse: {
          removeDocumentTag: {
            userErrors: [],
            document: {
              id: fragment.id,
              tags: fragment.tags.filter((t) => t !== tag),
              possibleTags: [...fragment.possibleTags, tag].sort(),
            },
          },
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Dialog {...dialogProps}>
      {children}
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t("DocumentTagsDialog.title")}</DialogTitle>
          <DialogDescription>
            <Trans
              i18nKey="DocumentTagsDialog.description"
              components={{ "word-break": <span className="break-all" /> }}
              values={{ name: fragment.name }}
            />
          </DialogDescription>
        </DialogHeader>

        <DocumentTagsInput
          fragment={fragment}
          onTagAdd={onTagAdd}
          onTagRemove={onTagRemove}
        />

        <div className="flex flex-col gap-3">
          <DialogTitle asChild>
            <h3>{t("DocumentTagsDialog.suggestions")}</h3>
          </DialogTitle>
          <DocumentTagsSuggestions fragment={fragment} onTagAdd={onTagAdd} />
        </div>

        <DialogFooter>
          <DialogClose asChild>
            <AccessButton>{t("DocumentTagsDialog.done")}</AccessButton>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
