import { ButtonIconLabel } from "@acdc2/ui/components/button-icon-label";
import { Button } from "@acdc2/ui/components/button";
import { mdiFileUploadOutline } from "@mdi/js";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { DocumentUploadDialog } from "./DocumentUploadDialog";
import { DialogTrigger } from "@acdc2/ui/components/dialog";

export function DocumentUploadButton(): JSX.Element {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  const onOpenChange = (newIsOpen: boolean) => {
    setIsOpen(newIsOpen);
  };

  useEffect(() => {
    const onDragOver = (event: DragEvent) => {
      event.preventDefault();
      setIsOpen(true);
    };

    document.addEventListener("dragover", onDragOver);

    return () => {
      document.removeEventListener("dragover", onDragOver);
    };
  }, []);

  return (
    <DocumentUploadDialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogTrigger asChild>
        <Button>
          <ButtonIconLabel
            path={mdiFileUploadOutline}
            description={t("DocumentUploadButton.label")}
          />
        </Button>
      </DialogTrigger>
    </DocumentUploadDialog>
  );
}
