import { ButtonIconLabel } from "@acdc2/ui/components/button-icon-label";
import { DropdownMenuItem } from "@acdc2/ui/components/dropdown-menu";
import { mdiAccountPlus } from "@mdi/js";
import { useTranslation } from "react-i18next";
import { DialogTrigger } from "@acdc2/ui/components/dialog";
import { DocumentShareDialog } from "./DocumentShareDialog";
import { DocumentShareDrowdownMenuItemFragment } from "../client/generated";

type Props = {
  fragment: DocumentShareDrowdownMenuItemFragment;
};

export function DocumentShareDropdownMenuItem({
  fragment,
}: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <DocumentShareDialog fragment={fragment}>
      <DialogTrigger asChild>
        <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
          <ButtonIconLabel
            path={mdiAccountPlus}
            description={t("DocumentShareDropdownMenuItem.label")}
          />
        </DropdownMenuItem>
      </DialogTrigger>
    </DocumentShareDialog>
  );
}
