import { ButtonIconLabel } from "@acdc2/ui/components/button-icon-label";
import { DropdownMenuItem } from "@acdc2/ui/components/dropdown-menu";
import { mdiTrashCan } from "@mdi/js";
import { useTranslation } from "react-i18next";
import { DialogTrigger } from "@acdc2/ui/components/dialog";
import { useState } from "react";
import { DocumentDeleteDialog } from "./DocumentDeleteDialog";
import { DocumentDeleteDropdownMenuItemFragment } from "../client/generated";

type Props = {
  fragment: DocumentDeleteDropdownMenuItemFragment;
};

export function DocumentDeleteDropdownMenuItem({
  fragment,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <DocumentDeleteDialog
      fragment={fragment}
      open={isOpen}
      onOpenChange={setIsOpen}
    >
      <DialogTrigger asChild>
        <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
          <ButtonIconLabel
            path={mdiTrashCan}
            description={t("DocumentDeleteDropdownMenuItem.label")}
          />
        </DropdownMenuItem>
      </DialogTrigger>
    </DocumentDeleteDialog>
  );
}
