import { ButtonIconLabel } from "@acdc2/ui/components/button-icon-label";
import { DropdownMenuItem } from "@acdc2/ui/components/dropdown-menu";
import { mdiTagMultiple } from "@mdi/js";
import { useTranslation } from "react-i18next";
import { DialogTrigger } from "@acdc2/ui/components/dialog";
import { useApolloClient } from "@apollo/client";
import { DocumentTagsDialog } from "./DocumentTagsDialog";
import { DocumentTagsDropdownMenuItemFragment } from "../client/generated";

type Props = {
  fragment: DocumentTagsDropdownMenuItemFragment;
};

export function DocumentTagsDropdownMenuItem({ fragment }: Props): JSX.Element {
  const { t } = useTranslation();
  const apolloClient = useApolloClient();

  const onOpenChange = (isOpen: boolean) => {
    if (!isOpen) {
      // Update possible tags for all other documents
      apolloClient.refetchQueries({ include: "active" });
    }
  };

  return (
    <DocumentTagsDialog fragment={fragment} onOpenChange={onOpenChange}>
      <DialogTrigger asChild>
        <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
          <ButtonIconLabel
            path={mdiTagMultiple}
            description={t("DocumentTagsDropdownMenuItem.label")}
          />
        </DropdownMenuItem>
      </DialogTrigger>
    </DocumentTagsDialog>
  );
}
