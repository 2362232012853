import { ButtonIconLabel } from "@acdc2/ui/components/button-icon-label";
import { DialogTrigger } from "@acdc2/ui/components/dialog";
import { DropdownMenuItem } from "@acdc2/ui/components/dropdown-menu";
import { mdiExport } from "@mdi/js";
import { useTranslation } from "react-i18next";
import { DocumentExportDropdownMenuItemFragment } from "../client/generated";
import { DocumentExportDialog } from "./DocumentExportDialog";

type Props = {
  fragment: DocumentExportDropdownMenuItemFragment;
};

export function DocumentExportDropdownMenuItem({
  fragment,
}: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <DocumentExportDialog fragment={fragment}>
      <DialogTrigger asChild>
        <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
          <ButtonIconLabel
            path={mdiExport}
            description={t("DocumentExportDropdownMenuItem.label")}
          />
        </DropdownMenuItem>
      </DialogTrigger>
    </DocumentExportDialog>
  );
}
