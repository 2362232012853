import { Badge } from "@acdc2/ui/components/badge";
import { mdiClose } from "@mdi/js";
import { Icon } from "@mdi/react";

type Props = {
  tags: string[];
  placeholder?: string;
  onTagAdd: (tag: string) => void;
  onTagRemove: (tag: string) => void;
};

export function AccessTagInput({
  tags,
  placeholder,
  onTagAdd,
  onTagRemove,
}: Props) {
  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && event.currentTarget.value) {
      onTagAdd(event.currentTarget.value);
      event.currentTarget.value = "";
    } else if (event.key === "Backspace" && event.currentTarget.value === "") {
      const lastTag = tags.at(-1);
      if (lastTag) {
        onTagRemove(lastTag);
      }
    }
  };

  const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (event.currentTarget.value) {
      onTagAdd(event.currentTarget.value);
      event.currentTarget.value = "";
    }
  };

  return (
    <div className="border border-input rounded-md bg-background p-2 flex flex-wrap gap-2 focus-within:ring-2 focus-within:ring-ring focus-within:ring-offset-2 ring-offset-background">
      {tags.map((tag) => (
        <Badge
          key={tag}
          variant="outline"
          className="flex items-center gap-1 pr-1"
        >
          {tag}
          <button
            type="button"
            onClick={() => onTagRemove(tag)}
            className="rounded-full p-0.5 hover:bg-destructive/50 hover:text-destructive-foreground"
          >
            <Icon path={mdiClose} size="1em" />
          </button>
        </Badge>
      ))}
      <input
        type="text"
        className="flex-1 min-w-8 h-6 outline-none text-sm text-muted-foreground bg-transparent"
        placeholder={placeholder}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
        autoFocus
      />
    </div>
  );
}
