import { toast } from "sonner";
import { ComponentProps, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Input } from "@acdc2/ui/components/input";
import { AccessButton } from "@acdc2/ui/components/access-button";
import { Button } from "@acdc2/ui/components/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@acdc2/ui/components/dialog";
import {
  DocumentRenameDialogFragment,
  useRenameDocumentMutation,
} from "../client/generated";

type Props = ComponentProps<typeof Dialog> & {
  fragment: DocumentRenameDialogFragment;
};

export function DocumentRenameDialog({
  fragment,
  children,
  onOpenChange,
  ...dialogProps
}: Props): JSX.Element {
  const [newName, setNewName] = useState(fragment.name);
  const { t } = useTranslation();

  const [renameDocument, { loading }] = useRenameDocumentMutation();

  const onSubmit = async () => {
    try {
      await renameDocument({
        variables: { documentId: fragment.id, newName },
      });
      onOpenChange?.(false);
    } catch (error) {
      toast(t("DocumentRenameDialog.error"), {
        description: new String(error),
      });
    }
  };

  return (
    <Dialog onOpenChange={onOpenChange} {...dialogProps}>
      {children}
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t("DocumentRenameDialog.title")}</DialogTitle>
          <DialogDescription>
            <Trans
              i18nKey="DocumentRenameDialog.description"
              components={{ "word-break": <span className="break-all" /> }}
              values={{ name: fragment.name }}
            />
          </DialogDescription>
        </DialogHeader>
        <Input
          placeholder={t("DocumentRenameDialog.placeholder")}
          value={newName}
          onChange={(event) => setNewName(event.target.value)}
        />
        <DialogFooter>
          <DialogClose asChild>
            <Button type="button" variant="secondary">
              {t("DocumentRenameDialog.cancel")}
            </Button>
          </DialogClose>

          <AccessButton
            loading={loading}
            disabled={newName.length === 0 || loading}
            onClick={onSubmit}
            children={t("DocumentRenameDialog.submit")}
          />
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
